import { render, staticRenderFns } from "./SiteVidicon.vue?vue&type=template&id=bbb58784&scoped=true"
import script from "./SiteVidicon.vue?vue&type=script&lang=js"
export * from "./SiteVidicon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb58784",
  null
  
)

export default component.exports